import { FeaturePage } from "../components/FeaturePage";
import React from 'react'

export default () => (
  <FeaturePage
    heading1="Take decisive actions with comprehensive analytics"
    heading2="Have complete business visibility with analytics and insight-driven dashboards. Drive data-backed decisions that keep your business aligned with growth"
    heading3="Unearth your revenue story"
    headerBlocks={[
      "Probe multiple perspectives for each metric to get a holistic view of your operations.",
      "Derive intelligent insights about your business with treatment, package, and sales analytics.",
      "Drive alignment across the organisation, with role specific KPI dashboards derived from a single source of truth.",
      "Set up notifications when key business metrics violate their expected thresholds.",
    ]}
    featurePageBlocks={[
      {
        chip: 'Spa overview and revenue reports',
        heading: 'A single view of your business’s growth and risk stats',
        body: [
          "Go beyond assumption and see accurate, up-to-date reports on every aspect of your spa business - including booking numbers, revenue forecasting, product sales, customer trends, staff reports and more.",
        ]
      },
      {
        chip: 'Finance reports',
        heading: 'Seal revenue leakage with gap-proof financial analysis',
        body: [
          'Drill into recurring and non-recurring revenue streams and analyse payments and refunds. Investigate leakage by analysing metrics across all revenue sources.',
        ],
      },
    ]}
  />
)
